import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { language } from '@environments/language';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  _language = language;

  getFilePath(): string {
    return environment.apiFileManagerUrl + '/open-api/download/';
  }

  getDicNameByLanguage() {
    let fieldName;
    switch (this._language.language) {
      case 'kz':
        fieldName = 'nameKz';
        break;
      case 'en':
        fieldName = 'nameEn';
        break;
      default:
        fieldName = 'nameRu';
        break;
    }
    return fieldName;
  }

  public serializeRealPropertyDataToCard(data: any, isFromFavorites?: boolean) {
    return data.map((item: any) => {
      return {
        id: item.applicationId,
        realPropertyId: item.realPropertyId,
        isFavorite: isFromFavorites ? true : item.isFavorite,
        price: item.objectPrice,
        viewCount: item.viewCount,
        photoIdList: this.createPhotoArray(item.photoIdList),
        agent: {
          id: item.agent.userId,
          fullName: `${item.agent.surname} ${item.agent.name}`,
          photoUuid: item.agent.photoUuid,
          phone: item.agent.phone,
        },
        address: `
              г. ${item.address.city[this.getDicNameByLanguage()]}, 
              ${item.address.district[this.getDicNameByLanguage()]} район, 
              ул. ${item.address.street[this.getDicNameByLanguage()]} ${item.address.building}`,
        area: item.totalArea,
        roominess: `${item.numberOfRooms} комнатная`,
        surface: `${item.floor || ''} ${item.numberOfFloors ? ' из ' + item.numberOfFloors : ''}`,
        residentialComplexName: `${item.residentialComplexName ? 'ЖК ' + item.residentialComplexName : ''}`,
      };
    });
  }

  createPhotoArray(photoIdList: string[]) {
    if (!photoIdList.length) {
      return [];
    }
    return photoIdList.slice(0, 5).map((image) => this.getFilePath() + image);
  }

  transItemForCardObject(item: any, user: any) {
    return {
      realPropertyId: item.realPropertyId,
      id: item.applicationId,
      agent: {
        phone: user?.phoneNumber,
        fullName: `${user?.name || ''} ${user?.surname || ''}`,
        photoUuid: user?.photoUuid,
      },
      price: item.objectPrice,
      viewCount: item.viewCount,
      isFavorite: item.isFavorite,
      residentialComplexName: item.residentialComplexName,
      photoIdList: item.photoIdList ? this.createPhotoArray(item.photoIdList) : [],
      address: item.address ? item.address[this.getDicNameByLanguage()].replace(',', ', р-н ').replace(' улица', ', ул.') : '',
      area: item.totalArea,
      roominess: `${item.numberOfRooms} комнатная`,
      surface: `${item.floor || ''}${item.numberOfFloors ? ' из ' + item.numberOfFloors : ''}`,
    };
  }
}
