import { AvatarComponent } from './avatar/avatar.component';
import { ModalComponent } from './modal/modal.component';
import { NoPhotoComponent } from './no-photo/no-photo.component';
import { YandexMapOldComponent } from './yandex-map/yandex-map.component';
import { NameComponent } from './name/name.component';
import { NetworkImageComponent } from './network-image/network-image.component';
import { PhoneComponent } from './phone/phone.component';
import { SupportRequestBtnComponent } from './support-request-button/support-request-btn.component';
import { LinkComponent } from './link/link.component';
import { RealPropertyDetailsLinkComponent } from '@shared/components/real-property-details-link/real-property-details-link.component';
import { RealPropertyAgentLinkComponent } from '@shared/components/real-property-agent-link/real-property-agent-link.component';
import { RealPropertyMediaContentComponent } from '@shared/components/real-property-media-content/real-property-media-content.component';
import { GoToSearchButtonComponent } from '@modules/go-to-search-button/go-to-search-button.component';
import { InputMaskComponent } from './phone-input/phone-input.component';

export const components = [
  NoPhotoComponent,
  NameComponent,
  NetworkImageComponent,
  PhoneComponent,
  InputMaskComponent,
  ModalComponent,
  YandexMapOldComponent,
  SupportRequestBtnComponent,
  LinkComponent,
  RealPropertyDetailsLinkComponent,
  RealPropertyAgentLinkComponent,
  RealPropertyMediaContentComponent,
  GoToSearchButtonComponent,
];

export * from './avatar/avatar.component';
export * from './no-photo/no-photo.component';
export * from './name/name.component';
export * from './network-image/network-image.component';
export * from './phone/phone.component';
export * from './modal/modal.component';
export * from './yandex-map/yandex-map.component';
export * from './link/link.component';
export * from './real-property-details-link/real-property-details-link.component';
export * from './real-property-agent-link/real-property-agent-link.component';
export * from './real-property-media-content/real-property-media-content.component';
