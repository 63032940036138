import { Injectable } from '@angular/core';
import {
  BehaviorSubject, combineLatest,
  fromEvent,
  interval,
  Observable, Subject, takeWhile,
} from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {

  public requestCountSubject = new BehaviorSubject<number>(0);
  requestCount$ = this.requestCountSubject.asObservable();

  // private percentCountSubject = new BehaviorSubject<number>(0);
  // percentCount$ = this.percentCountSubject.asObservable();

  isProcess: boolean;
  destroyPreloader$ = new Subject();

  constructor() {
    // this.checkAppLoaded().subscribe(() => {
    //   this.percentCountSubject.next(100);
    //   this.destroyPreloader$.next(null);
    //   this.isProcess = false;
    // });
  }

  startPercentCount() {
    const totalTime = 5000;
    const totalSteps = 100;

    return interval(totalTime / totalSteps).pipe(
      map((step) => (step + 1)),
      takeWhile((percentage) => percentage <= totalSteps),
      takeUntil(this.destroyPreloader$),
      // tap((percentage) => console.log(`${percentage}%`)),
    );
  }

  // startPercentCount() {
  //   if (!this.isProcess) {
  //     this.percentCountSubject.next(0);
  //     this.isProcess = true;
  //     interval(100).pipe(
  //       takeWhile((count) => count <= 100),
  //       takeUntil(this.destroyPreloader$),
  //     ).subscribe({
  //       next: (value) => {
  //         const currentValue = this.percentCountSubject.value;
  //         if (value > currentValue && value <= 99) {
  //           this.percentCountSubject.next(value);
  //         }
  //       }
  //     });
  //   }
  // }

  setRequestsCount(count: number) {
    this.requestCountSubject.next(count);
  }

  checkAppLoaded(): Observable<boolean> {
    return combineLatest([
      this.requestCount$.pipe(filter((r) => r === 0), map(() => true)),
      fromEvent(window, 'load').pipe(map(() => true)),
    ]).pipe(filter(([con1, con2]) => con1 && con2), map(() => true))
  }
}
