<div class="container">
    <div class="content">
        <div class="progress-image">
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="dynamicGradient" [attr.x1]="percent" y1="0" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                        <stop offset="0%" [style.stop-color]="startColor"/>
                        <stop [style.offset]="percent + '%'" [style.stop-color]="gradientColor" />
                        <stop offset="100%" [style.stop-color]="endColor"/>
                    </linearGradient>
                </defs>

                <path fill="url(#dynamicGradient)" fill-rule="evenodd" clip-rule="evenodd" d="M48.8109 51.6075H23.9502C23.4855 51.6075 23.137 51.1386 23.3113 50.6699L23.6598 49.7323C23.776 49.4394 24.0083 49.2637 24.2987 49.2637H48.4045C48.6948 49.2637 48.9272 49.4394 49.0433 49.7323L49.45 50.6699C49.6242 51.0801 49.2757 51.6075 48.8109 51.6075Z"/>
                <path fill="url(#dynamicGradient)" fill-rule="evenodd" clip-rule="evenodd" d="M46.1978 37.3099L47.2433 25.7081C47.3013 25.1221 46.5463 24.8877 46.2558 25.3565L40.7957 34.4974C40.5053 34.9661 39.8083 34.849 39.692 34.3217L36.904 23.7744C36.7879 23.2471 36.0328 23.2471 35.8585 23.7744L33.0703 34.3217C32.9542 34.849 32.1991 34.9661 31.9667 34.4974L26.5066 25.4151C26.2162 24.9463 25.4611 25.1807 25.5192 25.7667L26.5647 37.3686C26.6229 37.9546 25.9258 38.3061 25.5192 37.8959L18.3746 30.9817C17.968 30.5714 17.271 31.0403 17.5033 31.5677L23.2538 45.8648C23.5443 46.5681 24.2413 47.0368 24.9964 47.0368H47.7661C48.5212 47.0368 49.2182 46.5681 49.5086 45.8648L55.1429 31.5677C55.3753 31.0403 54.6783 30.5714 54.2717 30.9817L47.2433 37.8372C46.8367 38.2474 46.1397 37.8959 46.1978 37.3099Z"/>
                <path fill="url(#dynamicGradient)" d="M16.0517 29.9268C16.982 29.9268 17.7362 29.1661 17.7362 28.2277C17.7362 27.289 16.982 26.5283 16.0517 26.5283C15.1214 26.5283 14.3672 27.289 14.3672 28.2277C14.3672 29.1661 15.1214 29.9268 16.0517 29.9268Z"/>
                <path fill="url(#dynamicGradient)" d="M24.9384 24.3019C25.8687 24.3019 26.623 23.5411 26.623 22.6026C26.623 21.6641 25.8687 20.9033 24.9384 20.9033C24.0081 20.9033 23.2539 21.6641 23.2539 22.6026C23.2539 23.5411 24.0081 24.3019 24.9384 24.3019Z"/>
                <path fill="url(#dynamicGradient)" d="M47.8252 24.3019C48.7555 24.3019 49.5095 23.5411 49.5095 22.6026C49.5095 21.6641 48.7555 20.9033 47.8252 20.9033C46.8947 20.9033 46.1406 21.6641 46.1406 22.6026C46.1406 23.5411 46.8947 24.3019 47.8252 24.3019Z"/>
                <path fill="url(#dynamicGradient)" d="M56.5947 30.1028C57.525 30.1028 58.2793 29.3419 58.2793 28.4034C58.2793 27.465 57.525 26.7041 56.5947 26.7041C55.6644 26.7041 54.9102 27.465 54.9102 28.4034C54.9102 29.3419 55.6644 30.1028 56.5947 30.1028Z"/>
                <path fill="url(#dynamicGradient)" d="M36.3797 21.841C37.3102 21.841 38.0642 20.3981 38.0642 18.6182C38.0642 16.8384 37.3102 15.3955 36.3797 15.3955C35.4494 15.3955 34.6953 16.8384 34.6953 18.6182C34.6953 20.3981 35.4494 21.841 36.3797 21.841Z"/>
                <path fill="url(#dynamicGradient)" fill-rule="evenodd" clip-rule="evenodd" d="M36 1.9983C17.2214 1.9983 1.9983 17.2214 1.9983 36C1.9983 54.7786 17.2214 70.0015 36 70.0015C54.7786 70.0015 70.0015 54.7786 70.0015 36C70.0015 17.2214 54.7786 1.9983 36 1.9983ZM0 36C0 16.1177 16.1177 0 36 0C55.8821 0 72 16.1177 72 36C72 55.8821 55.8821 72 36 72C16.1177 72 0 55.8821 0 36Z"/>
            </svg>
        </div>
        <div class="progress-title">Загрузка {{ percent }}%</div>
    </div>

</div>
