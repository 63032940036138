import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProgressService } from '@app/progress/progress.service';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {

  constructor(private progressService: ProgressService) {}

  count = 0;
  activeRequest: string[] = [];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.activeRequest.push(req.url);
            this.progressService.setRequestsCount(this.activeRequest.length);
            break;
          case HttpEventType.Response:
            this.activeRequest = this.activeRequest.filter((r) => r != req.url);
            this.progressService.setRequestsCount(this.activeRequest.length);
            break;
        }
      }),
    );
  }
}
