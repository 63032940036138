import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-preloader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent implements OnInit, OnDestroy {
  @Input() percent: number = 0;

  public startColor = '#EDEDED';
  public endColor = '#572BA3';
  public gradientColor = '#8D4BFF';

  constructor() {}

  ngOnInit() {
    document.querySelector('body').style.overflow = 'hidden';
  }

  ngOnDestroy() {
    document.querySelector('body').style.overflow = 'auto';
  }
}
